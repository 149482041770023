@if (OutageIdToRemove == 0) {
<div class="container-fluid">
  <div class="row">
    <div class="col-md-7">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <nj-radio-group errorMessage="" [(value)]="SearchByDate" [hasError]="false" orientation="column">
              <nj-radio inputId="SearchByDateOfEventOcurrence" name="SearchByDate" value="EventOcurrence">
                Date Of Occurrence
              </nj-radio>
              <nj-radio inputId="SearchByDateAll" name="SearchByDate" value="All">
                All
              </nj-radio>
            </nj-radio-group>
          </div>
          <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
              [isFloatingLabel]="true" iconName="" inputId="RDRBDateOfPublish">
              <input type="date" id="RDRBDateFrom" njFormField [(ngModel)]="SearchDateOcurrence" [disabled]="SearchByDate == 'All'" />
              <ng-container njFormLabel>Date Of Occurrence</ng-container>
              <ng-container njFormSubscript></ng-container>
            </nj-form-item>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
              [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true" inputId="BMUs">
              <select njFormField id="BMUs" [(ngModel)]="SearchBMUs">
                <option value='ALL'>View All</option>
                <option *ngFor="let unit of BMUList" value='{{unit}}'>{{unit}}</option>
              </select>
              <ng-container njFormLabel>Units</ng-container>
              <ng-container njFormSubscript>
              </ng-container>
            </nj-form-item>
          </div>
          <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
              [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true" inputId="BMUs">
              <select njFormField id="AvailabiltyType" [(ngModel)]="SearchAvailabilityType">
                <option value='ALL'>View All</option>
                <option *ngFor="let availabilityType of AvailabilityTypesList" value='{{availabilityType.Id}}'>
                  {{availabilityType.Description}}</option>
              </select>
              <ng-container njFormLabel>Availability Type</ng-container>
              <ng-container njFormSubscript>
              </ng-container>
            </nj-form-item>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div [style]="'padding: 16px'" [style.background]="'transparent'">
      <nj-button size="normal" type="button" [isDisabled]="false" icon="" [hasCustomIcon]="false"
        (buttonClick)="GetAvailability()">
        Search
      </nj-button>
    </div>
    <div [style]="'padding: 16px'" [style.background]="'transparent'">
      <nj-button size="normal" type="button" [isDisabled]="IsRemitReporter == false" icon="" [hasCustomIcon]="false"
        (buttonClick)="CreateNew()">
        Create New
      </nj-button>
    </div>
    <div [style]="'padding: 16px'" [style.background]="'transparent'">
      <nj-button
          size="normal"
          type="button"
          icon=""
          [hasCustomIcon]="false"
          (buttonClick)="ExportToExcel()">
      Export to CSV
      </nj-button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">Last search datetime: {{LastSearch}} </div>
  </div>
  <div class="row">
    <div class="col-md-12">All times displayed are in BST</div>
  </div>
  <div class="row">
    <table id="tblRemits" class="nj-table nj-table--hover nj-table--striped nj-cursor">
      <thead>
        <tr [style]="'background-color: #d8d8d8'">
          <th (click)="SortData('id')">Id</th>
          <th (click)="SortData('eventStartTime')">Event Start Time</th>
          <th (click)="SortData('eventEndTime')">Event End Time</th>
          <th (click)="SortData('affectedUnit')">BMU</th>
          <th (click)="SortData('unavailabilityType')">Unavailability Type</th>
          <th (click)="SortData('unavailabilityCode')">Code / Value</th>
          <th (click)="SortData('reasonForUnavailability')">Cause</th>
          <th (click)="SortData('internalNotes')">Internal Notes</th>
          <th (click)="SortData('durationUncertainty')">Duration Uncertainty</th>
          <th (click)="SortData('notifiedOn')">Notified On</th>
          <th (click)="SortData('notifiedBy')">Notified By</th>
          <th (click)="SortData('auditDate')">Last Publish Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        @for(remit of currentOutagePageData; track remit; let i = $index)
        {
        <tr [style]="remit.remitsSubmitted == false ? 'background-color: rgb(230, 117, 117)': ''">
          <td>{{remit.id}}</td>
          <td>{{DateFormatHelper.GetDisplayDate(remit.eventStartTime)}}</td>
          <td>{{DateFormatHelper.GetDisplayDate(remit.eventEndTime)}}</td>
          <td>{{remit.affectedUnit}}</td>
          <td>{{remit.unavailabilityType.toLowerCase() == "maintenance" ? "Planned" : 
            remit.unavailabilityType.toLowerCase() == "forced" ? "Unplanned" : remit.unavailabilityType }}</td>
          <td>{{GetEventAvailabilityDescription(remit.unavailabilityCode, remit.remitTypeToGenerate)}}<br />{{remit.unavailabilityValue}}</td>
          <td>{{remit.reasonForUnavailability}}</td>
          <td>{{remit.internalNotes}}</td>
          <td>{{remit.durationUncertainty}}</td>
          <td>{{DateFormatHelper.GetDisplayDate(remit.notifiedOn)}}</td>
          <td>{{remit.notifiedBy}}</td>
          <td>{{DateFormatHelper.GetDisplayDate(remit.auditDate)}}</td>
          <td>
            <nj-button size="normal" type="button" [isDisabled]="false" icon="" [hasCustomIcon]="false"
              (buttonClick)="GetRemitRevisions(remit.id)">
              Revisions
            </nj-button><br/>
            <nj-button size="normal" type="button" [isDisabled]="CanCancel(remit.eventEndTime) == false || remit.remitsSubmitted == false" icon="" [hasCustomIcon]="false"
              [style]="'margin-top: 10px;'" (buttonClick)="CancelRemit(remit.id)">
              Cancel
            </nj-button>
            @if (remit.remitsSubmitted == false && UserCanResubmitREMIT == true) {
            <nj-button size="normal" type="button" [isDisabled]="false" icon="" [hasCustomIcon]="false"
              (buttonClick)="Resubmit(remit.id)">
              Re-submit
            </nj-button>
            }
          </td>
        </tr>
        @if (remit.remitsSubmitted == false)
        {
         <tr [style]="'background-color: rgb(230, 117, 117);'">
          <td colspan="13" [style]="'text-align: center;font-weight: bold;'">This remit was not submitted to Elexon. Please contact GEMS IS support 02071180662</td>
         </tr> 
        }
      }
      </tbody>
    </table>
    <nj-pagination
      [count]="PagesCount" 
      [shouldMinify]="true" (selectedItem)="OutagesShowPage($event)"></nj-pagination>
  </div>
</div>
}
@else {
  <app-outage-cancellation [OutageId]="OutageIdToRemove" (Close)="OutageDelete_Closed($event)"></app-outage-cancellation>
}