import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseHttpApiService } from './base-http.service';
import { ListItemData } from '../Dtos/list-item-data';
import { BMUExtendedInfo } from '../Dtos/bmu-extended-Info.entity';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService extends BaseHttpApiService{

  constructor(private http : HttpClient) { 
    super();
  }

  public GetBMUs() : Observable<string[]> {
    return this.http.get<string[]>(this.apiURL + "Remits/BMU");
  }

  public GetBMUExtendedInfos() : Observable<BMUExtendedInfo[]> {
    return this.http.get<BMUExtendedInfo[]>(this.apiURL + "Remits/BMUExtendedInfo");
  }

  public GetAvailabilityTypes() : Observable<ListItemData[]> {
    let at = [new ListItemData("Maintenance", "Planned"), new ListItemData("Forced", "Unplanned")];
    return of(at);
//    return this.http.get<string[]>(this.apiURL + "Remits/AvailiabilityTypes");
  }

  public GetReasons(bmu: string) : Observable<string[]> {
    return this.http.get<string[]>(this.apiURL + "Remits/Reasons/?bmu=" + bmu);
  }

  public GetNotificationsMethods() : Observable<string[]> {
    return this.http.get<string[]>(this.apiURL + "Remits/NotificationMethod");
  }

  public GetNotifiers(bmu: string): Observable<string[]> {
    return this.http.get<string[]>(this.apiURL + "Remits/Notifiers/?bmu=" + bmu);
  }
}
