<div class="row">
    <div class="col-md-12" [style]="'color:red'">New outage overlaps with existing outage, do you wish to continue?</div>
</div>
<div class="row" [style]="'margin-top: 15px;'">
    <table id="tblRemits" class="nj-table nj-table--hover nj-table--striped nj-cursor">
        <thead>
        <tr [style]="'background-color: #d8d8d8'">
            <th>Event Start Time</th>
            <th>Event End Time</th>
            <th>BMU</th>
            <th>Unavailability Type</th>
            <th>Code / Value</th>
            <th>Cause</th>
            <th>Internal Notes</th>
            <th>Duration Uncertainty</th>
            <th>Notified On</th>
            <th>Notified By</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let remit of OverlapOutagesData; let i = index">
            <td>{{DateFormatHelper.GetDisplayDate(remit.eventStartTime)}}</td>
            <td>{{DateFormatHelper.GetDisplayDate(remit.eventEndTime)}}</td>
            <td>{{remit.affectedUnit}}</td>
            <td>{{remit.unavailabilityType}}</td>
            <td>{{GetEventAvailabilityDescription(remit.unavailabilityCode, remit.remitTypeToGenerate)}}<br />{{remit.unavailabilityValue}}</td>
            <td>{{remit.reasonForUnavailability}}</td>
            <td>{{remit.internalNotes}}</td>
            <td>{{remit.durationUncertainty}}</td>
            <td>{{DateFormatHelper.GetDisplayDate(remit.notifiedOn)}}</td>
            <td>{{remit.notifiedBy}}</td>
        </tr>
        </tbody>
    </table>
</div>
<div class="row">
    <div class="col-md-3">
        <nj-button size="normal" type="button" [isDisabled]="false" icon="" [hasCustomIcon]="false"
            (buttonClick)="ResolveIsAccepted(true)">
            Accept
        </nj-button>
    </div>
    <div class="col-md-3">
        <nj-button size="normal" type="button" [isDisabled]="false" icon="" [hasCustomIcon]="false"
            (buttonClick)="ResolveIsAccepted(false)">
            Cancel
        </nj-button>
    </div>
</div>
