import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvailabilityOutageMinDTO } from 'src/app/core/Dtos/Remit';
import { DateFormatHelperService } from 'src/app/core/services/date-format-helper.service';
import { BaseComponent } from '../../base-component';
import { AlertsService } from 'src/app/core/services/alerts.service';

@Component({
  selector: 'app-outage-overlap',
  templateUrl: './outage-overlap.component.html',
  styleUrls: ['./outage-overlap.component.scss']
})
export class OutageOverlapComponent extends BaseComponent {
  @Input()
  public OverlapOutagesData:AvailabilityOutageMinDTO[] = [];
  
  @Output()
  public IsAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public DateFormatHelper: DateFormatHelperService, private alertService: AlertsService)
  {
    super(alertService, DateFormatHelper);
  }

  public ResolveIsAccepted(isAccepted: boolean) {
    this.IsAccepted.next(isAccepted);
  }
}
